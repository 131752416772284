<template>
  <client-only>
    <transition-root appear as="template" :show="isOpen">
      <dialog-root
        as="div"
        class="relative z-[1000]"
        :open="isOpen"
        @close="close"
      >
        <div
          class="fixed inset-0 flex pt-[10vh] md:px-4 md:py-8"
          :class="{
            'overflow-hidden': scrollable,
            'overflow-auto': !scrollable,
          }"
        >
          <transition-child
            as="template"
            enter="duration-700 ease-out"
            enter-from="opacity-0"
            leave="duration-200 ease-in"
            leave-to="opacity-0"
            :show="isOpen"
          >
            <dialog-backdrop
              class="fixed inset-0 z-[999] bg-black/30"
              @click.stop
            />
          </transition-child>

          <transition-child
            as="template"
            enter="transition duration-200 ease-out"
            enter-from="opacity-0 translate-y-4"
            :show="isOpen"
          >
            <dialog-panel
              class="relative mx-auto md:my-auto w-full md:max-w-[--width] rounded-t-lg md:rounded-lg bg-white shadow-lg"
              v-bind="$attrs"
              :class="{
                'overflow-auto md:overflow-visible': !scrollable,
                flex: $slots['aside-right'],
              }"
              data-cy-dialog
              :style="{
                '--width': `${width}px`,
              }"
            >
              <app-form
                class="flex flex-col md:h-auto"
                :class="{
                  'h-[calc(100dvh-10vh)] md:max-h-[calc(var(--maxBodyHeight)-theme(spacing.16))]':
                    scrollable,
                  'flex-auto': $slots['aside-right'],
                }"
                :style="{
                  '--maxBodyHeight': maxBodyHeight
                    ? `${maxBodyHeight}px`
                    : '100dvh',
                }"
                :submitting="submitting"
                @submit="$emit('submit')"
              >
                <template v-if="header">
                  <app-dialog-image-header
                    v-if="prependImage"
                    :close-button="closeButton"
                    :prepend-image="prependImage"
                    :subtitle="subtitle"
                    :title="title"
                    @close="close"
                  >
                    <template v-if="$slots.title" #title>
                      <slot name="title" />
                    </template>

                    <template v-if="$slots.subtitle" #subtitle>
                      <slot name="subtitle" />
                    </template>
                  </app-dialog-image-header>

                  <app-dialog-header
                    v-else
                    :close-button="closeButton"
                    :icon="icon"
                    :subtitle="subtitle"
                    :title="title"
                    @close="close"
                  >
                    <template v-if="$slots.title" #title>
                      <slot name="title" />
                    </template>

                    <template v-if="$slots.subtitle" #subtitle>
                      <slot name="subtitle" />
                    </template>
                  </app-dialog-header>
                </template>

                <template v-if="$slots.default">
                  <app-divider v-if="header && !prependImage" />

                  <div
                    class="p-4 md:px-6 flex-auto md:py-8"
                    :class="{
                      '!pt-0': prependImage,
                      'overflow-auto': scrollable,
                    }"
                  >
                    <slot />
                  </div>
                </template>

                <template v-if="$slots.footer">
                  <app-divider v-if="$slots.default" />

                  <div class="p-4 md:p-6" data-cy-dialog-footer>
                    <slot name="footer" />
                  </div>
                </template>
              </app-form>

              <div v-if="$slots['aside-right']">
                <slot name="aside-right" />
              </div>
            </dialog-panel>
          </transition-child>
        </div>
      </dialog-root>
    </transition-root>
  </client-only>
</template>

<script lang="ts" setup>
import {
  Dialog as DialogRoot,
  DialogBackdrop,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import type { TranslateResult } from "vue-i18n";

defineOptions({
  inheritAttrs: false,
});

const properties = withDefaults(
  defineProps<{
    modelValue?: boolean;
    icon?: string;
    title?: TranslateResult;
    subtitle?: TranslateResult;
    prependImage?: string;
    width?: number;
    maxBodyHeight?: number;
    submitting?: boolean;
    header?: boolean;
    scrollable?: boolean;
    closeButton?: boolean;
  }>(),
  {
    icon: undefined,
    title: undefined,
    subtitle: undefined,
    prependImage: undefined,
    width: 400,
    maxBodyHeight: undefined,
    header: true,
    scrollable: true,
    closeButton: true,
  },
);

const emit = defineEmits(["submit", "close"]);

const isOpen = useVModel(properties, "modelValue");

const close = () => {
  isOpen.value = false;
  emit("close");
};

watch(() => useRoute().path, close);
</script>
